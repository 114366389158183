var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.items.length >= 2)?_c('v-select',_vm._g({ref:"select",staticClass:"v-select--select-bold filter-select elevation-2",class:("v-input--" + _vm.size + " input-dropdown " + _vm.customClasses),attrs:{"value":_vm.valueData,"solo":"","items":_vm.items,"item-value":_vm.itemValue,"item-text":_vm.itemText,"menu-props":{
    offsetY: true,
    nudgeBottom: '8px',
    closeOnContentClick: false,
    contentClass: 'custom-dropdown-select custom-dropdown-select--plans',
  },"required":_vm.required,"hide-details":_vm.showDetails ? false : true,"return-object":_vm.returnObject},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [_c('loader')]},proxy:true},{key:"selection",fn:function(ref){
  var item = ref.item;
return [_c('div',{staticClass:"d-flex flex-column py-3 w-100"},[_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.splitName(item[_vm.itemText])[0]))]),_c('span',{staticClass:"font-weight-600 p-4 gray--text text--darken-1 text-truncate",staticStyle:{"max-width":"calc(100% - 24px)"}},[_vm._v(" "+_vm._s(_vm.splitName(item[_vm.itemText])[1])+" ")])])]}},{key:"prepend-inner",fn:function(){return [_vm._t("prepend-inner",function(){return [(_vm.prefix)?_c('div',{staticClass:"v-text-field__prefix"},[_vm._v(" "+_vm._s(_vm.prefix)+" ")]):_vm._e()]})]},proxy:true},{key:"item",fn:function(ref){
  var item = ref.item;
  var on = ref.on;
  var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"input-dropdown-item"},'div',attrs,false),on),[_c('div',{staticClass:"d-flex flex-column",staticStyle:{"max-width":"100%"}},[_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.splitName(item[_vm.itemText])[0]))]),_c('span',{staticClass:"font-weight-600 p-4 gray--text text--darken-1 text-truncate"},[_vm._v(" "+_vm._s(_vm.splitName(item[_vm.itemText])[1])+" ")])]),_c('v-icon',{staticClass:"ml-2 input-dropdown-item__icon"},[_vm._v("$check")])],1)]}},{key:"append",fn:function(){return [_c('v-icon',{attrs:{"size":"16"}},[_vm._v("$chevrondown")])]},proxy:true}],null,true),model:{value:(_vm.valueData),callback:function ($$v) {_vm.valueData=$$v},expression:"valueData"}},_vm.$listeners)):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }